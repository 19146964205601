import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var IndustryDetails = (function (_super) {
    __extends(IndustryDetails, _super);
    function IndustryDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IndustryDetails.prototype.mounted = function () {
        detailsModule.getApplicationObj(this.id);
    };
    Object.defineProperty(IndustryDetails.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IndustryDetails.prototype, "applicationObj", {
        get: function () {
            return detailsModule.applicationObj;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], IndustryDetails.prototype, "id", void 0);
    IndustryDetails = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], IndustryDetails);
    return IndustryDetails;
}(Vue));
export default IndustryDetails;
